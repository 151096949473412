<template>
  <div id="vbanner-show-page">
    <vs-popup classContent="popup-example" :title="$t('uploadFile')" :active.sync="isUploading">
      <ul class="vx-row centerx px-2">
        <li class="mx-4 mt-4 mb-3 pr-2">
          <vs-radio v-validate="'required'" name="child_type" v-model="file_type" vs-value="video">{{ $t('video') | capitalize }}</vs-radio>
        </li>
        <li class="mx-4 mt-4 mb-3 pr-2">
          <vs-radio v-validate="'required'" name="child_type" v-model="file_type" vs-value="photo">{{ $t('fields.image') | capitalize }}</vs-radio>
        </li>
      </ul>
      <div v-if="loading" class="mx-3">
        <vs-progress :percent="(uploadPercentage)" :color="getPopularityColor(uploadPercentage)" class="shadow-md" />
        {{ uploadPercentage }}%
      </div>
      <div class="vx-col upload-img mt-3">
        <form v-if="file_type" enctype="multipart/form-data" @submit.prevent="uploadFile">
          <div class="flex m-3">
            <!-- <label for="file">Upload File</label> -->
            <input v-validate="'required'" type="file" ref="file" name="file" class="hidden" @change="selectFile">
            <input v-validate="'required'" type="file" ref="photoInput" name="photoInput" class="hidden" @change="selectPhoto">
            <template v-if="!(file || imageFileName)">
              <vs-button v-if="file_type === 'video'" @click="$refs.file.click()" type="border" icon-pack="feather" icon="icon-plus">{{ `${$t('select')} ${$t('video')}` }}</vs-button>
              <vs-button v-else-if="file_type === 'photo'" @click="$refs.photoInput.click()" type="border" icon-pack="feather" icon="icon-plus">{{ `${$t('select')} ${$t('fields.image')}` }}</vs-button>
            </template>
            <span v-else-if="file_type === 'video'">{{ file.name }}</span>
            <span v-else>{{ imageFileName }}</span>
            <vs-button type="filled" icon-pack="feather" icon="icon-upload" class="ml-auto" :disabled="loading" @click="uploadFile">
              <span v-if="!loading">{{$t('upload')}}</span>
              <img v-else src="@/assets/images/loading1.gif" alt="" width="20" class="mx-3">
            </vs-button>
          </div>
        </form>
      </div>
    </vs-popup>
    <vs-popup v-if="popupActive" class="holamundo" title="Hello video" :active.sync="popupActive">
      <div class="w-full mb-base">
        <vx-card class="overflow-hidden">
          <template slot="no-body">
            <video-player ref="player" class="media-video-player card-video" :options="playerOptions" />
          </template>
        </vx-card>
      </div>
    </vs-popup>
    <div v-if="isLoading" class="flex w-full h-full p-24">
      <img src="@/assets/images/loading1.gif" alt="" width="50" class="mx-auto">
    </div>
    <vx-card v-show="!isLoading" class="mb-6">
      <vs-row class="mb-3">
        <h3 class="mr-auto">{{ banner[`title_${$i18n.locale}`] }}</h3>
        <div v-if="!banner.video && !banner.image" class="btn-delete p-3 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary" @click="upload">
          <feather-icon icon="UploadIcon" svgClasses="h-4 w-4" />
          <span class="ml-2 text-base">{{ $t('uploadFile') }}</span>
        </div>
        <div v-else class="btn-delete p-3 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-danger border border-solid border-danger" @click="deleteFile">
          <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" />
          <span class="ml-2 text-base">{{ $t('deleteFile') }}</span>
        </div>
        <router-link :to="{name: 'vBannerEdit', params: {id: $route.params.id}}" class="py-3 px-6 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary">
          <feather-icon icon="EditIcon" svgClasses="h-4 w-4" />
          <span class="ml-2 text-base text-primary">{{ $t('edit') }}</span>
        </router-link>
      </vs-row>
      <vs-table hoverFlat stripe :data="Object.keys(banner)">
        <template slot="thead">
          <vs-th>{{$t('property')}}</vs-th>
          <vs-th>{{$t('value')}}</vs-th>
        </template>
        <template>
          <vs-tr v-if="banner.preview">
            <vs-td><span class="uppercase font-medium">{{ $t('props.preview') }}</span></vs-td>
            <vs-td>
              <div class="flex flex-row overflow-x-auto" style="width: 100%;">
                <div class="img-container relative">
                  <a target="_blank" :href="`${$baseUrl}/videobanners/preview/${banner.uuid}.webp`">
                    <img :src="`${$baseUrl}/videobanners/preview/${banner.uuid}.webp`" alt="" class="h-40">
                  </a>
                </div>
                <div class="img-container relative ml-3">
                  <a target="_blank" :href="`${$baseUrl}/videobanners/image/${banner.uuid}.webp`">
                    <img v-if="banner.image" :src="`${$baseUrl}/videobanners/image/${banner.uuid}.webp`" alt="" class="h-40">
                  </a>
                </div>
              </div>
            </vs-td>
          </vs-tr>
          <vs-tr v-for="(prop, idx) in Object.keys(banner)" :key="idx">
            <vs-td v-if="prop !== 'collectionId'">
              <span class="uppercase font-medium">{{$t(`props.${prop}`)}}</span>
            </vs-td>
            <vs-td v-if="(banner[prop] === true || banner[prop] === false || banner[prop] === null) && prop !== 'collectionId'">
              <vs-chip :color="banner[prop] && prop === 'video' ? 'primary' : banner[prop] ? 'success' : 'danger'">
                <feather-icon v-if="banner[prop] && prop === 'video'" icon="PlayIcon" svgClasses="cursor-pointer w-5 h-5" @click="playVideo" role="button"/>
                <feather-icon v-else-if="banner[prop]" icon="CheckIcon" svgClasses="w-5 h-5" />
                <feather-icon v-else icon="XIcon" svgClasses="w-5 h-5" />
              </vs-chip>
            </vs-td>
            <vs-td v-else-if="prop === 'products'">
              <router-link v-for="item in banner[prop]" :key="item.id" :to="`/products/${item.uuid}`" class="font-medium">{{ item[`name_${$i18n.locale}`] }}, </router-link>
            </vs-td>
            <vs-td v-else-if="prop === 'collection'">
              <router-link :to="`/combo-products/${banner[prop].uuid}`" class="font-medium">{{ banner[prop][`name_${$i18n.locale}`] }}</router-link>
            </vs-td>
            <vs-td v-else-if="prop === 'brand'">
              <router-link :to="`/brands/${banner[prop].uuid}`" class="font-medium">{{ banner[prop][`name_${$i18n.locale}`] }}</router-link>
            </vs-td>
            <vs-td v-else-if="prop === 'likes'">
              {{ banner[prop].length }}
            </vs-td>
            <vs-td v-else-if="prop !== 'collectionId' && prop !== 'photo'">
              {{ banner[prop] }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vx-card>
  </div>
</template>

<script>
import { videoPlayer } from 'vue-video-player'
import 'video.js/dist/video-js.css'

export default {
  components: { videoPlayer },
  data () {
    return {
      imageFileName: '',
      popupActive: false,
      uploadPercentage: 0,
      banner: {},
      file_type: '',
      file: '',
      isUploading: false,
      isLoading: true,
      loading: false,
      playerOptions: {
        autoplay: false,
        fluid: true,
        height: '300',
        language: 'en',
        muted: true,
        playbackRates: [0.7, 1, 1.5, 2],
        poster: '',
        sources: [
          {
            src: '',
            type: 'video/mp4'
          }
        ]
      }
    }
  },
  watch: {
    file_type () {
      this.file = ''
      this.banner.photo = ''
      this.uploadPercentage = 0
    }
  },
  computed: {
    getPopularityColor () {
      return e => {
        if (0 < e && e < 60) return 'danger'
        else if (61 < e && e < 100) return 'warning'
        else if (e === 100) return 'primary'
      }
    }
  },
  methods: {
    playVideo () {
      this.playerOptions.sources[0].src = `${this.$baseUrl}/videobanners/video/${this.banner.uuid}.mp4`
      this.popupActive = true
    },
    async fetchData () {
      await this.$http.get(`/videobanners/${this.$route.params.id}`)
        .then((response) => { 
          this.banner = response.data
          this.isLoading = false
        })
        .catch(() => { this.isLoading = false })
    },
    upload () { this.isUploading = true },
    deleteFile () {
      const key = this.banner.video ? 'video' : 'image'
      this.$http.delete(`/videobanners/delete-file/${this.banner.uuid}`, { params: { key } }).then(() => { this.fetchData() })
    },
    selectFile () { this.file = this.$refs.file.files[0] },
    selectPhoto (input) {
      this.imageFileName = input.target.files[0].name
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        reader.onload = e => { this.banner.photo = e.target.result }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    async uploadFile () {
      this.loading = true
      if (this.file_type === 'video') {
        const formData = new FormData()
        formData.append(`${this.file_type}`, this.file)
        await this.$http.post(`/videobanners/upload-${this.file_type}/${this.banner.uuid}`, formData, {
          onUploadProgress: (progress) => {
            const precentage = parseInt(Math.round((progress.loaded / progress.total) * 100))
            this.uploadPercentage = precentage > 1 ? precentage - 1 : 0
          }
        }).then(res => {
          this.uploadPercentage = 100
          this.banner = res.data
          this.loading = false
          this.isUploading = false
          this.notify(this.$t('notify.success'), `${this.$t('props.video')} ${this.$t('notify.sUploaded')}`, 'icon-check-circle', 'primary')
        }).catch((err) => {
          this.loading = false
          this.isUploading = false
          this.notify(this.$t('notify.error'), err.response.data.message, 'icon-alert-circle', 'danger')
        })
      } else {
        await this.$http.post(`/videobanners/upload-${this.file_type}/${this.banner.uuid}`, { photo: this.banner.photo }, {
          onUploadProgress: (progress) => {
            const precentage = parseInt(Math.round((progress.loaded / progress.total) * 100))
            this.uploadPercentage = precentage > 1 ? precentage - 1 : 0
          }
        }).then(res => {
          this.uploadPercentage = 100
          this.banner = res.data
          this.loading = false
          this.isUploading = false
          this.notify(this.$t('notify.success'), `${this.$t('props.image')} ${this.$t('notify.sUploaded')}`, 'icon-check-circle', 'primary')
        }).catch((err) => {
          this.loading = false
          this.isUploading = false
          this.notify(this.$t('notify.error'), err.response.data.message, 'icon-alert-circle', 'danger')
        })
      }
    },
    notify (title, text, icon, color) {
      this.$vs.notify({ title, text, iconPack: 'feather', icon, color })
    }
  },
  async created () { this.fetchData() }
}
</script>
